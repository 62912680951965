
<template>
    <div>
        <el-form
            v-loading="dataLoading"
            :model="formData"
            :rules="rules"
            ref="formData"
            label-position="top"
            size="medium"
        >
            <div class="page-group-title"><span>基本信息（客户：{{data && data.customerName}}）</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="headDraw" label="头像:">
                        <image-manage 
                            :image-list.sync="formData.headDraw" 
                            :limit="1" 
                            @onUploadSuccess="uploadHeadDraw" 
                            previewWidth="160px" 
                            previewHeight="200px"
                            ></image-manage>
                            <!-- <el-image :src="formData.headDraw" fit="cover" style="width:160px;height:200px;"></el-image> -->
                    </el-form-item>
                    <el-form-item prop="status" label="状态:">
                        <el-radio-group v-model="formData.status">
                            <el-radio :label="1">编辑中</el-radio>
                            <el-radio :label="2">展示中</el-radio>
                            <el-radio :label="0">禁用</el-radio>
                            <el-radio :label="-1">删除</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="light" label="点灯:">
                        <el-radio-group v-model="formData.light">
                            <el-radio :label="1">开启</el-radio>
                            <el-radio :label="0">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="lijain" label="礼金:">
                        <el-radio-group v-model="formData.lijain">
                            <el-radio :label="1">开启</el-radio>
                            <el-radio :label="0">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="template" label="模板:">
                        <el-select v-model="formData.template" placeholder="选择模板">
                            <el-option value="1" label="父亲"></el-option>
                            <el-option value="2" label="母亲"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="title" label="标题:">
                        <el-input v-model.trim="formData.title" placeholder="请输入标题" maxlength="200"></el-input>
                    </el-form-item>
                    <el-form-item prop="content" label="讣告正文:" :show-message="formData.content ? false : true">
                        <!-- <tinymce v-if="reFresh" v-model="formData.content" :height="500" class="MT10"></tinymce> -->
                        <el-input type="textarea" v-model="formData.content" :rows="5" maxlength="1000" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item prop="dataLine" label="落款:">
                        <el-input type="textarea" v-model="formData.dataLine" placeholder="请输入落款" :rows="3" maxlength="200"></el-input>
                    </el-form-item>
                    <el-form-item prop="musicCode" label="背景音乐:">
                        <el-select v-model="formData.musicCode" placeholder="请选择背景音乐">
                            <el-option v-for="item in musicList" :key="item.code" :value="item.code" :label="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>  
            <div class="page-group-title"><span>灵堂信息</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="lintangAddress" >
                        <span slot="label">
                            灵堂地址:
                            <el-button type="text" icon="el-icon-map-location" @click="$refs.selectAddress1.show()">选择地址</el-button> 
                            <small style="margin-left:10px" v-if="formData.lintangLong">(经度: {{formData.lintangLong}} 纬度: {{formData.lintangLati}})</small>
                        </span>
                        <el-input v-model="formData.lintangAddress" placeholder="百度地图根据搜索结果返回的地址" size="medium" maxlength="100"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <select-address 
                ref="selectAddress1" 
                :address.sync="formData.lintangAddress" 
                :lng.sync="formData.lintangLong" 
                :lat.sync="formData.lintangLati">
            </select-address>

            <div class="page-group-title"><span>告别会信息</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="gaobieTime" label="告别时间:">
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-date-picker
                                v-model="formData.gaobieDate"
                                type="date"
                                placeholder="选择日期"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="12">
                                <el-select placeholder="请选择时间" v-model="formData.gaobieTimer">
                                    <el-option v-for="item in timerList" :key="item" :value="item" :label="`${item}时`"></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="gaobieAddress" >
                        <span slot="label">
                            告别会地址:
                            <el-button type="text" icon="el-icon-map-location" @click="$refs.selectAddress2.show()">选择地址</el-button> 
                            <small style="margin-left:10px" v-if="formData.lintangLong">(经度: {{formData.gaobieLong}} 纬度: {{formData.gaobieLati}})</small>
                        </span>
                        <el-input v-model="formData.gaobieAddress" placeholder="百度地图根据搜索结果返回的地址" size="medium" maxlength="100"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <select-address 
                ref="selectAddress2" 
                :address.sync="formData.gaobieAddress" 
                :lng.sync="formData.gaobieLong" 
                :lat.sync="formData.gaobieLati">
            </select-address>

            <div class="page-group-title"><span>答谢宴信息</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="daxieTime" label="答谢时间:">
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-date-picker
                                v-model="formData.daxieDate"
                                type="date"
                                placeholder="选择日期"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="12">
                                <el-select placeholder="请选择时间" v-model="formData.daxieTimer">
                                    <el-option v-for="item in timerList" :key="item" :value="item" :label="`${item}时`"></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="daxieAddress" >
                        <span slot="label">
                            答谢宴地址:
                            <el-button type="text" icon="el-icon-map-location" @click="$refs.selectAddress3.show()">选择地址</el-button> 
                            <small style="margin-left:10px" v-if="formData.daxieLong">(经度: {{formData.daxieLong}} 纬度: {{formData.daxieLati}})</small>
                        </span>
                        <el-input v-model="formData.daxieAddress" placeholder="百度地图根据搜索结果返回的地址" size="medium" maxlength="100"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <select-address 
                ref="selectAddress3" 
                :address.sync="formData.daxieAddress" 
                :lng.sync="formData.daxieLong" 
                :lat.sync="formData.daxieLati">
            </select-address>

            <div class="page-group-title"><span>相关图片</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :lg="22" :xl="24">
                    <el-form-item prop="photoList">
                        <image-manage :image-list.sync="formData.photoList"></image-manage>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="page-group-title"><span>相关视频</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :lg="22" :xl="24">
                    <el-form-item prop="videoCoverList">
                        <video-manage :video-cover-list.sync="formData.videoCoverList" :video-list.sync="formData.videoList"></video-manage>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-divider></el-divider>
        <div class="form-btns">
            <el-button @click="goBack">返回</el-button>
            <el-button type="primary"  @click="onSubmit" :loading="btnLoading">保存</el-button>
        </div>
    </div>
</template>

<script>
import VideoManage from '@/components/VideoManage';
import ImageManage from '@/components/ImageManage';
import SelectAddress from '@/components/SelectAddressBaiduMap';

const defaultFormData = {
    "businessId": "",
	"content": "",
	"customerId": "",
	"dataLine": "",
	"daxieAddress": "",
	"daxieLati": "",
	"daxieLong": "",
	"daxieTime": "",
	"gaobieAddress": "",
	"gaobieLati": "",
	"gaobieLong": "",
	"gaobieTime": "",
	"headDraw": [],
	"light": 1,
	"lijain": 1,
	"lintangAddress": "",
	"lintangLati": "",
	"lintangLong": "",
	"photoList": [],
	"template": "",
	"title": "",
	"videoCoverList": [],
	"videoList": [],
    "status": 1,
    gaobieDate: "",
    gaobieTimer: "00",
    daxieDate: "",
    daxieTimer: "00",
    musicCode: ''
}
export default {
    components:{VideoManage,ImageManage,SelectAddress},
    data(){
        return{
            redirect: this.$route.query.redirect || '/fugao/list',
            btnLoading: false,
            dataLoading: false,
            formData: {...defaultFormData},
            data: null,
            rules:{
                title: [{required: true, message: '请输入标题'}],
                headDraw: [{required: true, message: '请上传头像'}],
                // telephone: [{required: true, message: '请输入联系电话'},{ validator: this.$utils.validatePhone }],
                dataLine: [{required: true, message: '请填写讣告落款'}],
                template: [{required: true, message: '请选择模板'}],
                content: [{required: true, message: '请填写讣告内容'}],
            },
            reFresh:false,
            timerList: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
            musicList: [
                {code:"aidebeishang", name:"爱的悲伤"},
                {code:"annidexiangjing", name:"安妮的仙境"},
                {code:"changzhemengxiang", name:"朝着梦想"},
                {code:"ChildhoodMemory", name:"Childhood Memory"},
                {code:"father", name:"父亲"},
                {code:"fatherbanzou", name:"父亲 (伴奏)"},
                {code:"fengjuzhudejiedao", name:"风居住的街道"},
                {code:"jiyiderongyan", name:"记忆的容颜"},
                {code:"luoyedeyoushang", name:"落叶的忧伤"},
                {code:"menglidegushi", name:"梦里的故事"},
                {code:"muyangnudebeisang", name:"牧羊女的悲伤"},
                {code:"shiguangcaiyang", name:"时光采样"},
                {code:"ThePromise", name:"The Promise"},
                {code:"tiankongzsszguang", name:"天空中闪烁着光"},
                {code:"VagrantPoet", name:"Vagrant Poet"},
                {code:"xiamodehai", name:"夏末的海"},
                {code:"youshangrhenganjing", name:"忧伤的很安静"},
                {code:"yudeyingji", name:"雨的印记"},
                {code:"zhuguanglidemama", name:"烛光里的妈妈"},
            ]
        }
    },
    created(){
    },
    mounted(){
        this.getDataDetail();
    },
    methods:{
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
        //获取编辑详情
        getDataDetail(){
            this.dataLoading = true;
            this.$request({
                url: '/api/ht/fugao/info',
                method: "POST",
                params: {
                    fugaoId: this.$route.query.fugaoId,
                    customerId: this.$route.query.customerId,
                },
            })
                .then((res) => {
                    //处理成功回调
                    const { state, errmsg, result } = res.data;
                    if (state && state == 1) {
                        this.data = {...result};
                        this.formData = {
                            ...defaultFormData,
                            ...result,
                            headDraw: result.headDraw && [result.headDraw] || [],
                            gaobieDate: result.gaobieTime && this.$utils.formatDate(result.gaobieTime) || '',
                            gaobieTimer: result.gaobieTime ? (result.gaobieTime.split(' ')[1]).split(':')[0] : "00",
                            daxieDate: result.daxieTime && this.$utils.formatDate(result.daxieTime) || '',
                            daxieTimer: result.daxieTime ? (result.daxieTime.split(' ')[1]).split(':')[0] : "00",
                        }
                    } else {
                        return this.$message.error(errmsg || "获取详情失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取详情失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                    this.reFresh = true;
                });
        },
        uploadHeadDraw(){
            this.$refs["formData"].clearValidate('headDraw');
        },
        onSubmit(){
            this.$refs["formData"].validate((valid) => {
                const _url = this.formData.id ? '/api/ht/fugao/edit' : '/api/ht/fugao/add'
                if (valid) {
                    this.btnLoading = true;
                    this.$request({
                        url: _url,
                        method: "POST",
                        data: {
                            ...this.formData,
                            headDraw: this.formData.headDraw.length > 0 && this.formData.headDraw[0] || '',
                            gaobieTime: this.formData.gaobieDate ? this.formData.gaobieDate + ' ' + this.formData.gaobieTimer : '',
                            daxieTime: this.formData.daxieDate ? this.formData.daxieDate + ' ' + this.formData.daxieTimer : '',
                        },
                    })
                        .then((res) => {
                            //处理成功回调
                            const { state, msg } = res.data;
                            if (state && state == 1) {
                                this.goBack();
                            } else {
                                return this.$message.error(msg || "操作失败");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }
            });
        }
    }
}
</script>