<template>
    <div class="video-manage-box">
        <div class="video-list-box">
            <image-preview v-for="(item,index) in videoCoverList" :key="index" :url="item" class="item" width="160px" height="90px">
                <div slot="tool">
                    <span class="el-icon-video-play" @click="$refs.videoPlay.show(videoList[index])"></span>
                    <el-button icon="el-icon-delete" type="text" @click="deleteVideo(index)"></el-button>
                </div>
            </image-preview>
            <el-upload
                action
                multiple
                :show-file-list="false"
                accept=".mp4"
                :http-request="uploadVideoHandle"
                >
                <div class="item upload-btn el-icon-plus"></div>
            </el-upload>
        </div>
        <small class="color-info">请选择上传mp4格式视频文件 <span class="color-danger" v-if="uploadVideoLoading">视频上传中...</span></small>
        <video-play ref="videoPlay"></video-play>
    </div>
</template>
<script>
import ImagePreview from '@/components/ImagePreview';
import VideoPlay from '@/components/VideoPlay';
import {uploadVideo} from '@/assets/js/commonApi.js';

export default {
    name: "VideoManage",
    props: {
        videoCoverList: {
            type: Array,
            default: () => []
        },
        videoList: {
            type: Array,
            default: () => []
        }
    },
    components:{
        ImagePreview,VideoPlay
    },
    data(){
        return{
            uploadVideoLoading: false
        }
    },
    methods:{
        //删除视频
        deleteVideo(index){
            const _videoCoverList = this.videoCoverList.filter((v,i) => i !== index);
            const _videoList = this.videoList.filter((v,i) => index !== i);
            this.$emit("update:videoCoverList",_videoCoverList);
            this.$emit("update:videoList",_videoList);
        },
        //上传视频
        async uploadVideoHandle(req){
            this.uploadVideoLoading = true;
            let res;
            const formData = new FormData();

            formData.append("file", req.file);

            res = await uploadVideo(formData,ev => {
                
            }).catch(() => {
                this.$message.error( '上传失败');
            }).finally(() => {
                this.uploadVideoLoading = false;
            });
            const {state,result,msg} = res.data;
            if( state && state == 1 && result){
                result.cover && this.videoCoverList.push(result.cover.urlfull)
                result.cover && result.urlfull && this.videoList.push(result.urlfull)
                this.$emit('onUploadSuccess', result);
            }else{
                this.$message.error( msg || '上传失败');
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.video-manage-box{
    .video-list-box{
        display: flex;
        flex-wrap: wrap;
        .item{
            margin: 10px 10px 0 0;
        }
        .upload-btn{
            width: 160px;
            height: 90px;
            border:1px #eee dashed;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 40px;
            color: #eee;
        }
    }
}
</style>